<template>
  <div v-if="page === 'profile'">
    <div class="profile">
      <form @submit.prevent="editUser">
        <h3 class="font-weight-bold d-block d-md-none mb-4">รายละเอียดบัญชี</h3>
        <div class="profile-img position-relative">
          <img v-if="displayImage" :src="displayImage" alt="" />
          <img v-else-if="imgUrl" :src="imgUrl" alt="" />
          <img v-else src="@/assets/images/profile/noprofile.png" alt="" />
          <label
            for="file"
            class="position-absolute"
            style="bottom: 0; right: 5px; cursor: pointer;"
          >
            <img
              style="border-radius:0"
              src="@/assets/images/profile/edit.svg"
              alt=""
            />
          </label>
          <input
            type="file"
            name="image"
            id="file"
            accept=".jpg, .jpeg"
            style="display: none"
            ref="fileInput"
            @change="onSelectFile($event)"
          />
        </div>
        <div
          style="border-bottom: 3px solid #07c5f8; width: 120px; margin: 20px auto;"
        ></div>
        <label class="color-primary font-weight-bold">ชื่อ</label>
        <input type="text" class="w-100" v-model="firstName" />
        <label class="color-primary font-weight-bold">นามสกุล</label>
        <input type="text" class="w-100" v-model="lastName" />
        <label class="color-primary font-weight-bold">อีเมล</label>
        <input type="text" disabled class="w-100" v-model="email" />
        <label class="color-primary font-weight-bold">หน่วยงาน</label>
        <input type="text" disabled class="w-100" v-model="organization.name" />
        <label class="color-primary font-weight-bold">ที่อยู่</label>
        <textarea
          v-model="address"
          rows="5"
          class="w-100 text-big"
          placeholder="ที่อยู่ของท่าน"
          required
        ></textarea>
        <label class="color-primary font-weight-bold">ตำบล/แขวง</label>
        <input
          type="text"
          class="w-100"
          placeholder="ตำบล/แขวง"
          v-model="subDistrict"
        />
        <label class="color-primary font-weight-bold">อำเภอ/เขต</label>
        <input
          type="text"
          class="w-100"
          placeholder="อำเภอ/เขต"
          v-model="district"
        />
        <label class="color-primary font-weight-bold">จังหวัด</label>
        <div class="position-relative">
          <select class="d-block w-100" v-model="province" required>
            <option
              v-for="province in provinceList"
              :key="province"
              :value="province"
              style="color: black;"
              >{{ province }}</option
            >
          </select>
          <img
            class="dropdown-icon"
            src="@/assets/images/dropdown-icon.svg"
            alt=""
          />
        </div>
        <label class="color-primary font-weight-bold">รหัสไปรษณีย์</label>
        <input
          type="number"
          class="w-100"
          placeholder="รหัสไปรษณีย์"
          v-model="postcode"
        />
        <label class="color-primary font-weight-bold">เบอร์โทรศัพท์</label>
        <input
          type="number"
          class="w-100"
          placeholder="เบอร์โทรศัพท์"
          v-model="phone"
        />
        <label class="color-primary font-weight-bold">เลขบัตรประชาชน</label>
        <input
          type="number"
          class="w-100"
          placeholder="เลขบัตรประชาชน"
          v-model="idCard"
        />
        <div v-if="typeLogin === 'email'">
          <p class="color-primary font-weight-bold m-0">รหัสผ่าน</p>
          <p
            class="color-secondary font-weight-bold"
            style="cursor: pointer;"
            @click="goToChangePassword"
          >
            <u>
              เปลี่ยนรหัสผ่าน
            </u>
          </p>
        </div>
        <button
          class="primary w-100"
          style="margin-top: 50px;"
          :disabled="imageLoading"
        >
          บันทึก
        </button>
      </form>
    </div>
  </div>
  <div v-else>
    <div class="profile">
      <form @submit.prevent="changePassword">
        <h3 class="font-weight-bold">
          <span
            style="margin-right:10px; cursor: pointer;"
            @click="page = 'profile'"
            >&#x276E;</span
          >
          เปลี่ยนรหัสผ่าน
        </h3>
        <label class="color-primary font-weight-bold">รหัสผ่านปัจจุบัน</label>
        <div class="position-relative">
          <input
            class="w-100"
            :type="passwordDisplay"
            v-model="password"
            minlength="8"
            placeholder="รหัสผ่านปัจจุบัน"
            required
          />
          <img
            class="password-icon"
            src="@/assets/images/showpassword.svg"
            alt=""
            @click="togglePasswordDisplay('password')"
          />
        </div>
        <label class="color-primary font-weight-bold"
          >รหัสผ่าน <span style="font-size:10px">(อย่างน้อย 8 ตัว)</span></label
        >
        <div class="position-relative">
          <input
            class="w-100"
            :type="newPasswordDisplay"
            v-model="newPassword"
            minlength="8"
            placeholder="รหัสผ่าน"
            required
          />
          <img
            class="password-icon"
            src="@/assets/images/showpassword.svg"
            alt=""
            @click="togglePasswordDisplay('newPass')"
          />
        </div>
        <label class="color-primary font-weight-bold"
          >ยืนยันรหัสผ่านใหม่<span
            style="font-size:10px; color: red"
            v-if="confirmPassword && newPassword !== confirmPassword"
          >
            ไม่ตรงกัน</span
          ></label
        >
        <div class="position-relative">
          <input
            class="w-100"
            :type="confirmPasswordDisplay"
            minlength="8"
            v-model="confirmPassword"
            placeholder="ยืนยันรหัสผ่าน"
            required
          />
          <img
            class="password-icon"
            src="@/assets/images/showpassword.svg"
            alt=""
            @click="togglePasswordDisplay('confirmPass')"
          />
        </div>
        <button class="secondary w-100" style="margin-top: 20px;">
          บันทึก
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "../../api/faAPI";
import normAxios from "axios";
import baseUrl from "../../util/backend";
import provinceList from "@/assets/mock/thaiprovince.json";
export default {
  data() {
    return {
      provinceList,
      imageLoading: false,
      displayImage: "",
      page: "profile",
      imgUrl: "",
      firstName: "",
      lastName: "",
      organization: "",
      email: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postcode: "",
      phone: "",
      idCard: "",
      typeLogin: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      passwordDisplay: "password",
      newPasswordDisplay: "password",
      confirmPasswordDisplay: "password",
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    goToChangePassword() {
      this.page = "password";
      window.scrollTo(0, 0);
    },
    async changePassword() {
      if (this.newPassword !== this.confirmPassword) {
        alert("รหัสผ่านไม่ตรงกัน");
        return;
      }
      this.$store.dispatch("setLoading", true);
      try {
        const res = await axios.put(`${baseUrl}/changePassword`, {
          user_id: this.$store.getters.getUser.user_id,
          password: this.password,
          newPassword: this.newPassword,
        });
        this.page = "profile";
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "error");
        } else {
          alert(err);
        }
      }
      this.$store.dispatch("setLoading", false);
    },
    togglePasswordDisplay(name) {
      if (name === "password") {
        this.passwordDisplay === "password"
          ? (this.passwordDisplay = "text")
          : (this.passwordDisplay = "password");
      }
      if (name === "newPass") {
        this.newPasswordDisplay === "password"
          ? (this.newPasswordDisplay = "text")
          : (this.newPasswordDisplay = "password");
      }
      if (name === "confirmPass") {
        this.confirmPasswordDisplay === "password"
          ? (this.confirmPasswordDisplay = "text")
          : (this.confirmPasswordDisplay = "password");
      }
    },
    async onSelectFile(e) {
      this.imageLoading = true;
      // console.log(e.target.files);
      console.log(e.target.files[0]);
      let fileFormData = new FormData();
      fileFormData.append("folder_id", 1);
      fileFormData.append("file", e.target.files[0]);
      let fileRes = await normAxios.post(
        `https://api.fastation.co.th/file/api/file/create`,
        fileFormData
      );
      this.displayImage = fileRes.data.data.uploadFileAtLevel.url;
      // console.log(e.target.files[0].name);
      // console.log(e.target.value.split(/(\\|\/)/g).pop());
      // for (const file of e.target.files) {
      //   console.log(file);
      //   this.fileList.push({
      //     name: file.name,
      //     file: file,
      //   });
      // }
      this.imageLoading = false;
    },
    async editUser() {
      try {
        const res = await axios.put(`${baseUrl}/updateUserDetail`, {
          user_id: this.userInfo.user_id,
          img_url: this.displayImage || this.imgUrl,
          first_name: this.firstName,
          last_name: this.lastName,
          address: this.address,
          district: this.district,
          province: this.province,
          sub_district: this.subDistrict,
          postcode: this.postcode,
          id_card: this.idCard,
          phone: this.phone,
        });
        console.log(res);
        this.$router.go();
      } catch (err) {
        if (err.response) {
          alert(err.response.data.error || "แก้ไขไม่สำเร็จ");
        } else {
          alert(err);
        }
      }
    },
  },
  created() {
    this.imgUrl = this.userInfo.img_url;
    this.firstName = this.userInfo.first_name;
    this.lastName = this.userInfo.last_name;
    this.email = this.userInfo.email;
    this.organization = this.userInfo.organization_users[0].organization;
    this.typeLogin = this.userInfo.type_login;
    this.address = this.userInfo.address;
    this.subDistrict = this.userInfo.sub_district;
    this.district = this.userInfo.district;
    this.province = this.userInfo.province;
    this.postcode = this.userInfo.postcode;
    this.phone = this.userInfo.phone;
    this.idCard = this.userInfo.id_card;
  },
};
</script>

<style scoped>
.profile {
  max-width: 360px;
  margin: 0 auto;
}

.profile-img {
  width: 90px;
  height: 90px;
  margin: 0 auto;
}

.profile-img img:first-of-type {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

input,
textarea {
  border: 1px solid #0430ad;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  outline: none;
  text-align: center;
}

select {
  color: #000;
  text-align: center;
  border: 1px solid #0430ad;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  background-color: transparent;
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: -1;
}

::-webkit-input-placeholder {
  color: #798da5;
  font-size: 16px;
}

:-moz-placeholder {
  color: #798da5;
  font-size: 16px;
}

::-moz-placeholder {
  color: #798da5;
  font-size: 16px;
}

:-ms-input-placeholder {
  color: #798da5;
  font-size: 16px;
}

button {
  padding: 10px;
  margin-bottom: 20px;
}

button.primary {
  background-color: #0430ad;
  color: #fff;
}

button.secondary {
  background-color: #07c5f8;
  color: #fff;
}

.password-icon {
  position: absolute;
  cursor: pointer;
  top: calc(50% - 5px);
  right: 10px;
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) {
  .profile-img {
    width: 150px;
    height: 150px;
  }
}
</style>
